import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/youtube";
import { slide as Menu } from "react-burger-menu";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const NotFound = ({ view }) => {
  switch (view) {
    case "NO_BRANCH_CUSTOMER_FOUND":
      return "No client found";
    default:
      return "";
  }
};

function AppNonTouchScreen({
  branchCustomers,
  view,
  directoryLogoUrl,
  directoryVideoUrl,
  directoryBackgroundBannerUrl,
  slidesToShow,
  autoplaySpeed,
  getMenuWidth,
  isLoading,
}) {
  const [calcWidth, setCalcWidth] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  let defaultSliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    vertical: true,
  };

  useEffect(() => {
    const handleResize = () => {
      const newWindowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      const viewportHeight = newWindowDimensions.height;
      const aspectRatio = 16 / 9;

      if (newWindowDimensions.width < viewportHeight * aspectRatio) {
        setCalcWidth(viewportHeight * aspectRatio);
      } else {
        setCalcWidth(newWindowDimensions.width);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="outer-container"
      className="App bg-gray-500 h-screen bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${directoryBackgroundBannerUrl})` }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Directory</title>
      </Helmet>
      <Menu
        right
        isOpen={true}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        width={getMenuWidth(viewportWidth)}
        disableOverlayClick
        //customBurgerIcon={false} // disable default hamburger icon
        customCrossIcon={null} // use custom close icon
      >
        <div className=" bg-black bg-opacity-50 min-h-screen">
          <div className=" h-screen flex flex-col justify-center">
            {isLoading && (
              <div className=" absolute h-full w-full bg-opacity-80 flex flex-col items-center">
                <div className=" text-2xl font-semibold absolute top-1/3">
                  <div className="flex justify-center mb-3">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
                  </div>
                  <span>Loading...</span>
                </div>
              </div>
            )}

            {view === "LIST_BRANCH_CUSTOMERS" && (
              <div className="space-y-4 p-3">
                <Slider
                  key={"slider"}
                  style={{
                    width: "90%",
                  }}
                  {...defaultSliderSettings}
                >
                  {branchCustomers.map((customer, index) => (
                    <div
                      key={index}
                      className="rounded p-2 cursor-default flex flex-row justify-between items-center mb-2"
                    >
                      <h2 className="text-lg font-semibold text-white">
                        {customer.customername}
                      </h2>
                      {customer.customerOfficeNumbers ? (
                        <span className="text-white">
                          {customer.customerOfficeNumbers}
                        </span>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            )}

            {[
              "NO_COMPANY_FOUND",
              "NO_BRANCH_FOUND",
              "NO_BRANCH_CUSTOMER_FOUND",
            ].includes(view) && (
              <div className=" h-96 flex flex-col items-center justify-center overflow-hidden">
                <h3 className=" text-2xl">{<NotFound view={view} />}</h3>
              </div>
            )}
          </div>
        </div>
      </Menu>

      <div
        id="page-wrap"
        className="container h-full mx-auto py-14 space-y-10 flex flex-col items-center justify-between"
      >
        <section className="w-full flex flex-col justify-center items-center">
          <div className=" w-96 flex flex-col justify-center items-center">
            {directoryLogoUrl && (
              <img src={directoryLogoUrl} alt="logo" title={"logo"} />
            )}
          </div>
        </section>
        {directoryVideoUrl && (
          <section className="player-wrapper flex-1 flex justify-center items-center w-11/12 md:w-7/12 rounded-lg overflow-hidden">
            <ReactPlayer
              height={(calcWidth / 16) * 9}
              width={calcWidth}
              playing={true}
              loop={true}
              muted={true}
              url={directoryVideoUrl}
              controls={false}
              style={{
                position: "absolute",
                objectFit: "fill",
                opacity: 1,
                backgroundColor: "black",
                maxHeight: "550px",
              }}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    controls: 0,
                  },
                },
                vimeo: {
                  playerOptions: {
                    controls: false,
                  },
                },
              }}
            />
          </section>
        )}
      </div>
    </div>
  );
}

export default AppNonTouchScreen;
