import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import AppTouchScreen from "./AppTouchScreen";
import AppNonTouchScreen from "./AppNonTouchScreen";

function App() {
  const { location } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [branchCustomers, setBranchCustomers] = useState([]);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [view, setView] = useState("LIST_BRANCH_CUSTOMERS"); // LIST_BRANCH_CUSTOMERS | NO_BRANCH_CUSTOMER_FOUND |
  const [notFound, setNotFound] = useState(false);
  const [directoryLogoUrl, setDirectoryLogoUrl] = useState(null);
  const [directoryVideoUrl, setDirectoryVideoUrl] = useState(null);
  const [directoryBackgroundBannerUrl, setDirectoryBackgroundBannerUrl] =
    useState(null);
  const [directoryDisplayDevice, setDirectoryDisplayDevice] =
    useState("TOUCH_SCREEN");
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [autoplaySpeed, setAutoplaySpeed] = useState(3000);
  const [calcWidth, setCalcWidth] = useState(0);

  /* get location id from url query param */
  useEffect(() => {
    initializeLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newWindowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      const viewportHeight = newWindowDimensions.height;
      const aspectRatio = 16 / 9;

      if (newWindowDimensions.width < viewportHeight * aspectRatio) {
        setCalcWidth(viewportHeight * aspectRatio);
      } else {
        setCalcWidth(newWindowDimensions.width);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Every hour fetch new items
  useEffect(() => {
    const timer = setInterval(() => {
      initializeLocation();
    }, 3600000); // 1 hour

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClients = async (_location) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL +
          "/ads/clients-lookup?location=" +
          _location
      );
      const { data } = response;
      if (data.locationInfo && data.locationInfo.directoryLogoUrl) {
        if (data.locationInfo.directoryLogoUrl) {
          setDirectoryLogoUrl(data.locationInfo.directoryLogoUrl);
        }
        if (data.locationInfo.directoryVideoUrl) {
          setDirectoryVideoUrl(data.locationInfo.directoryVideoUrl);
        }
        if (data.locationInfo.directoryBackgroundBannerUrl) {
          setDirectoryBackgroundBannerUrl(
            data.locationInfo.directoryBackgroundBannerUrl
          );
        }
        if (data.locationInfo.directoryItemsPerSlide) {
          setSlidesToShow(data.locationInfo.directoryItemsPerSlide);
        }

        if (data.locationInfo.directoryAutoplaySpeed) {
          setAutoplaySpeed(data.locationInfo.directoryAutoplaySpeed);
        }

        if (data.locationInfo.directoryDisplayDevice) {
          setDirectoryDisplayDevice(data.locationInfo.directoryDisplayDevice);
        }

        if (data.items.length > 0) {
          //console.log("data.items", data.items[0]);
          setBranchCustomers(data.items);
          //setBranchCustomers(data.items.slice(0, 5));
          setView("LIST_BRANCH_CUSTOMERS");
        } else {
          setView("NO_BRANCH_CUSTOMER_FOUND");
        }
        setIsLoading(false);
      } else {
        setNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotFound(true);
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initializeLocation = () => {
    if (location && location !== "") {
      fetchClients(location);
      // set in memory for future
      localStorage.setItem("location", location);
    } else if (localStorage.getItem("location")) {
      fetchClients(localStorage.getItem("location"));
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const _location = urlParams.get("location");
      if (_location) {
        localStorage.setItem("location", _location);
        fetchClients(_location);
      } else {
        setNotFound(true);
      }
    }
  };

  const getMenuWidth = (width) => {
    if (width > 1024) {
      return "40%";
    } else {
      return "100%";
    }
  };

  if (!isLoading && notFound) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Not Found</title>
        </Helmet>
        <div className="h-screen flex flex-col items-center justify-center">
          <div>Request not found</div>
        </div>
      </div>
    );
  } else {
    return directoryDisplayDevice === "TOUCH_SCREEN" ? (
      <AppTouchScreen
        branchCustomers={branchCustomers}
        viewportWidth={viewportWidth}
        view={view}
        directoryLogoUrl={directoryLogoUrl}
        directoryVideoUrl={directoryVideoUrl}
        directoryBackgroundBannerUrl={directoryBackgroundBannerUrl}
        slidesToShow={slidesToShow}
        autoplaySpeed={autoplaySpeed}
        calcWidth={calcWidth}
        getMenuWidth={getMenuWidth}
      />
    ) : (
      <AppNonTouchScreen
        branchCustomers={branchCustomers}
        view={view}
        directoryLogoUrl={directoryLogoUrl}
        directoryVideoUrl={directoryVideoUrl}
        directoryBackgroundBannerUrl={directoryBackgroundBannerUrl}
        slidesToShow={slidesToShow}
        autoplaySpeed={autoplaySpeed}
        getMenuWidth={getMenuWidth}
        isLoading={isLoading}
      />
    );
  }
}
export default App;
