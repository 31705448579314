import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player/youtube";
import { slide as Menu } from "react-burger-menu";
import { Helmet } from "react-helmet";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
//import "./App.css";
import Switch from "react-switch";

const INACTIVE_TIMEOUT = 60;

const CloseIcon = ({ width = 24, height = 24, color = "#000" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18L18 6M6 6L18 18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const NotFound = ({ view }) => {
  switch (view) {
    case "NO_BRANCH_CUSTOMER_FOUND":
      return "No client found";
    default:
      return "";
  }
};

function AppTouchScreen({
  branchCustomers,
  view,
  directoryLogoUrl,
  directoryVideoUrl,
  directoryBackgroundBannerUrl,
  slidesToShow,
  autoplaySpeed,
  getMenuWidth,
  isLoading,
}) {
  const [isOpen, setOpen] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [virtualKeyboard, setVirtualKeyboard] = useState(true);
  const [seconds, setSeconds] = useState(INACTIVE_TIMEOUT);
  const [calcWidth, setCalcWidth] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const keyboard = useRef();

  useEffect(() => {
    const handleResize = () => {
      const newWindowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      const viewportHeight = newWindowDimensions.height;
      const aspectRatio = 16 / 9;

      if (newWindowDimensions.width < viewportHeight * aspectRatio) {
        setCalcWidth(viewportHeight * aspectRatio);
      } else {
        setCalcWidth(newWindowDimensions.width);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      keyboard.current.clearInput();
      setSearchInput("");
      setOpen(false);
    }
  }, [seconds]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
    filterList(inputValue);
  };

  const filterList = (searchTerm) => {
    if (searchTerm === "") {
      setFilteredList([]);
    } else {
      const filtered = branchCustomers.filter((customer) =>
        customer.customername.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  const displayList = searchInput === "" ? branchCustomers : filteredList;

  const handleOpenMenu = () => {
    setOpen(!isOpen);
  };

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const handleIsClose = () => {
    setOpen(!isOpen);
  };

  const CustomCloseIcon = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick}>
        <CloseIcon width={32} height={32} color="#FFF" />
      </div>
    );
  };

  const keyboardOnChange = (input) => {
    setSearchInput(input);
    filterList(input);
    setSeconds(INACTIVE_TIMEOUT);
  };

  const keyboardOnKeyPress = (button) => {
    //console.log("Button pressed", button);
  };

  return (
    <div
      id="outer-container"
      className="App bg-gray-500 h-screen bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${directoryBackgroundBannerUrl})` }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Directory</title>
      </Helmet>
      <Menu
        right
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsClose}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        width={getMenuWidth(viewportWidth)}
        //customBurgerIcon={false} // disable default hamburger icon
        customCrossIcon={<CustomCloseIcon />} // use custom close icon
      >
        <div className=" bg-gray-100 min-h-screen relative">
          <div className=" bg-black shadow-xl text-white w-full py-3 px-5 flex flex-row space-x-2 fixed z-10">
            <input
              className=" bg-transparent h-16 w-96 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-900 focus:border-transparent"
              value={searchInput}
              onChange={handleSearchInputChange}
              placeholder="Type to search..."
            />
          </div>
          <div className="pt-24 relative">
            {isLoading && (
              <div className=" absolute h-full w-full bg-white bg-opacity-80 flex flex-col items-center">
                <div className=" text-2xl font-semibold absolute top-1/3">
                  <div className="flex justify-center mb-3">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
                  </div>
                  <span>Loading...</span>
                </div>
              </div>
            )}

            {view === "LIST_BRANCH_CUSTOMERS" && (
              <ul className="space-y-4 p-3">
                <div className="flex justify-between items-center">
                  <span className="mr-2 flex flex-row justify-center items-center">
                    {seconds && seconds > 0 && (
                      <span className=" text-xs text-gray-500  ml-2">
                        {seconds} seconds to clear inputs
                      </span>
                    )}
                  </span>
                  <Switch
                    onChange={() => setVirtualKeyboard(!virtualKeyboard)}
                    checked={virtualKeyboard}
                  />
                </div>
                {virtualKeyboard && (
                  <Keyboard
                    keyboardRef={(r) => (keyboard.current = r)}
                    onChange={(input) => keyboardOnChange(input)}
                    onKeyPress={(button) => keyboardOnKeyPress(button)}
                    holdTimeout={500}
                  />
                )}
                {displayList.map((customer, index) => (
                  <li
                    key={index}
                    className="bg-gray-50 rounded p-4 cursor-default flex flex-row justify-between items-center space-x-5"
                  >
                    <h2 className="text-lg font-semibold">
                      {customer.customername}
                    </h2>
                    {customer.customerOfficeNumbers ? (
                      <span>{customer.customerOfficeNumbers}</span>
                    ) : null}
                    {/*customer.officenumbers &&
                      customer.officenumbers.length > 0 && (
                        <span>{customer.officenumbers[0].officenumber}</span>
                      )*/}
                  </li>
                ))}
              </ul>
            )}

            {[
              "NO_COMPANY_FOUND",
              "NO_BRANCH_FOUND",
              "NO_BRANCH_CUSTOMER_FOUND",
            ].includes(view) && (
              <div className=" h-96 flex flex-col items-center justify-center overflow-hidden">
                <h3 className=" text-2xl">{<NotFound view={view} />}</h3>
              </div>
            )}
          </div>
        </div>
      </Menu>

      <div
        id="page-wrap"
        className="container h-full mx-auto py-14 space-y-10 flex flex-col items-center justify-between"
      >
        <section className="w-full flex flex-col justify-center items-center">
          <div className=" w-96 flex flex-col justify-center items-center">
            {directoryLogoUrl && (
              <img src={directoryLogoUrl} alt="logo" title={"logo"} />
            )}
          </div>
        </section>
        {directoryVideoUrl && (
          <section className="player-wrapper flex-1 flex justify-center items-center w-11/12 md:w-7/12 rounded-lg overflow-hidden">
            <ReactPlayer
              height={(calcWidth / 16) * 9}
              width={calcWidth}
              playing={true}
              loop={true}
              muted={true}
              url={directoryVideoUrl}
              controls={false}
              style={{
                position: "absolute",
                objectFit: "fill",
                opacity: 1,
                backgroundColor: "black",
                maxHeight: "550px",
              }}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    controls: 0,
                  },
                },
                vimeo: {
                  playerOptions: {
                    controls: false,
                  },
                },
              }}
            />
          </section>
        )}
        <section>
          <button
            onClick={handleOpenMenu}
            className="bg-black hover:bg-gray-700 text-white font-bold text-2xl py-5 px-10 rounded-full"
          >
            Search Directory
          </button>
        </section>
      </div>
    </div>
  );
}

export default AppTouchScreen;
